import PropTypes from "prop-types"
import React from "react"

import { writeToCookie, readFromCookie } from "./cookieHelper"

let defaultGlobalState = {
  language: readFromCookie("language") === "tr" ? "tr" : "en",
  displayNewYearModal:
    readFromCookie("displayNewYearModal") === "false" ? false : true,
}

const globalStateContext = React.createContext(defaultGlobalState)
const dispatchStateContext = React.createContext(undefined)

export const useGlobalState = () => [
  React.useContext(globalStateContext),
  React.useContext(dispatchStateContext),
]

const GlobalStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(
    (state, newValue) => {
      if (
        newValue &&
        newValue.language !== null &&
        newValue.language !== undefined
      ) {
        writeToCookie("language", newValue.language)
      }

      if (
        newValue &&
        newValue.displayNewYearModal !== null &&
        newValue.displayNewYearModal !== undefined
      ) {
        writeToCookie("displayNewYearModal", newValue.displayNewYearModal)
      }

      return { ...state, ...newValue }
    },

    defaultGlobalState
  )

  return (
    <globalStateContext.Provider value={state}>
      <dispatchStateContext.Provider value={dispatch}>
        {children}
      </dispatchStateContext.Provider>
    </globalStateContext.Provider>
  )
}

GlobalStateProvider.propTypes = {
  children: PropTypes.element,
}

export default GlobalStateProvider
